<template>
    <v-breadcrumbs :items="breadcumbs">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                :to="item.href"
                class="text-subtitle-2 crumb-item"
                :disabled="item.disabled"
                exact
            >
                {{ item.text }}
            </v-breadcrumbs-item>
        </template>
    </v-breadcrumbs>
</template>

<script>
import { mapState } from 'vuex'
// import {menu} from "@/backend-api/menu/index" 
  
export default {
    data() {
        return {
            user: ''
        }
    },
    props:['breadcumbs'],
    computed: {
        cardHeight () {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return '250'
                case 'sm': return '260'
                case 'md': return '350'
                case 'lg': return '350'
                case 'xl': return '350'
            }
        },
    },
    async mounted(){
        console.log(this.breadcumbs);
    },
    methods: {
    }
}
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .9;
  position: absolute;
  width: 100%;
}
.kelassatuin{
    background-color: #ea5044;
    color: #ea5044;
}
.kelasduain{
    background-color: #2eb3ec;
    color: #2eb3ec;
}
.kelastigain{
    background-color: #ee69a7;
    color: #ee69a7;
}
.kelasempatin{
    background-color: #f5aa3b;
    color: #f5aa3b;
}
.kelaslimain{
    background-color: #4bb4c6;
    color: #4bb4c6;
}
.kelasenamin{
    background-color: #54b857;
    color: #54b857;
}
.kelastujuhin{
    background-color: #5581ad;
    color: #5581ad;
}
.master_budget{
    background-color: #ea5044;
    color: #ea5044;
}

</style>